import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import WarningIcon from '@mui/icons-material/Warning';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import StyledWrapper from './style';
import { MUIDatePicker } from './MUI_DatePicker';

import { Registry } from '../../FormBuilderLibrary';
import { getTimeRemaining } from '../../../utils/dateUtils';
import { stringToHTML } from '../utils';

export const MUIStartEndDate = React.forwardRef((props, _ref) => {
	const { name, defaultValue, disabled, data, isFormBuilder } = props;

	const [startDate, setStartDate] = useState(new Date(defaultValue?.[0]));
	const [endDate, setEndDate] = useState(new Date(defaultValue?.[1]));
	const [totalTime, setTotalTime] = useState();
	const [timeRemaining, setTimeRemaining] = useState();
	const [warningIcon, setWarningIcon] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const calculateTimeRemaining = () => {
		if (startDate && endDate && startDate.toString() === endDate.toString()) {
			setTotalTime('0d 0h 0m');
		} else if ((startDate, endDate) && startDate < endDate) {
			const { timeRemaining } = getTimeRemaining(endDate, startDate);
			setTotalTime(timeRemaining);
		} else {
			setTotalTime(null);
		}
	};

	const calculateRemainingTime = () => {
		const currentDate = new Date();
		if (startDate && startDate >= currentDate) {
			setTimeRemaining('Not Started');
			setWarningIcon(false);
		} else if (endDate && currentDate <= endDate) {
			const { timeRemaining, days } = getTimeRemaining(endDate, currentDate);
			setTimeRemaining(timeRemaining);
			days <= 10 ? setWarningIcon(true) : setWarningIcon(false);
		} else {
			setTimeRemaining('0d 0h 0m');
			setWarningIcon(false);
		}
	};

	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	useEffect(() => {
		calculateTimeRemaining();
		calculateRemainingTime();
	}, [refresh, startDate, endDate]);

	const HelpText = () => (
		<FormHelperText error={props?.data?.error}>
			{props?.data?.error?.error || parsedHelperText?.[0] || props?.data?.help_message}
		</FormHelperText>
	);

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
				<div style={{ display: 'flex', gap: '10px' }}>
					<div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
						<MUIDatePicker
							defaultValue={defaultValue?.[0]}
							disabled={disabled}
							required={props?.data?.required}
							name={`${name}-start`}
							data={{
								label: `${data?.label ? data?.label + ' ' : ''}Start Date`,
								key: props?.data.key,
								unique_identifier: props?.data?.unique_identifier,
								temp_order: props?.data?.temp_order,
								min_date: data?.min_date || data?.min_value,
								max_date: endDate || data?.max_date || data?.max_value,
								error: data?.error
							}}
							sx={{ fontWeight: '100' }}
							onChange={(newValue) => {
								setStartDate(newValue);

								setRefresh(!refresh);
							}}
							isFormBuilder={isFormBuilder}
						/>
						<HelpText />
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
						<MUIDatePicker
							defaultValue={defaultValue?.[1]}
							disabled={disabled}
							required={props?.data?.required}
							name={`${name}-end`}
							data={{
								label: `${data?.label ? data?.label + ' ' : ''}End Date`,
								key: props?.data.key,
								unique_identifier: props?.data?.unique_identifier,
								temp_order: props?.data?.temp_order,
								min_date: startDate || data?.min_date || data?.min_value,
								max_date: data?.max_date || data?.max_value,
								error: data?.error
							}}
							onChange={(newValue) => {
								setEndDate(newValue);
								setRefresh(!refresh);
							}}
							isFormBuilder={isFormBuilder}
						/>
						<HelpText />
					</div>
				</div>

				{!data?.custom_options?.includes('hideTimePeriod') && (
					<div style={{ display: 'flex', gap: '10px' }}>
						<TextField
							error={props?.data?.error}
							id={`${name}-totalTime`}
							label={'Total Time'}
							value={totalTime || '(not defined)'}
							sx={{ width: '100%' }}
							disabled
							InputLabelProps={{ shrink: isFormBuilder || undefined }}
						/>
						<FormControl sx={{ width: '100%' }} variant="outlined">
							<StyledWrapper.CustomLabel
								error={props?.data?.error}
								value={timeRemaining || '(not defined)'}
								disabled
								htmlFor={`${name}-timeRemaining`}
								shrink={isFormBuilder || undefined}
							>
								{isFormBuilder ? 'Time Remaining' : ''}
							</StyledWrapper.CustomLabel>
							<StyledWrapper.OutlinedInputReadOnly
								type={'text'}
								disabled={true}
								style={{ width: '100%' }}
								inputComponent={() => {
									return (
										<>
											<TextField
												error={props?.data?.error}
												InputLabelProps={{ shrink: isFormBuilder || undefined }}
												id={`${name}-timeRemaining`}
												label={'Time Remaining'}
												sx={{ width: 'fit-content' }}
												value={timeRemaining || '(not defined)'}
												disabled
											/>
											{warningIcon && <WarningIcon sx={{ fill: '#ED6C02' }} />}
										</>
									);
								}}
								label={'Time Remaining'}
							/>
						</FormControl>
					</div>
				)}
			</div>
		</>
	);
});

MUIStartEndDate.displayName = 'MUI_StartEndDate';
Registry.register('MUI_StartEndDate', MUIStartEndDate);
