import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';

const LogicContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	max-width: 100%;
`;

const TextAreaContainer = styled.div`
	display: flex;
	margin-top: 20px;
	align-items: center;
	gap: 10px;
	width: 100%;
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 30%;

	& select {
		height: 30px;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
	gap: 5px;
`;

const AndOrLabel = styled.b`
	margin-left: 5px;
	font-size: 14px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.6);
`;

const AllDependancies = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	gap: 10px;
`;

const CurrentDependanciesContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0px;
	background-color: #eceff1;
	border-radius: 5px;
	padding: 5px;
`;

const GroupedDependancies = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0px;
	background-color: #eceff1;
	border-radius: 5px;
`;

const CurrentDependancies = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const dependancy = styled.p`
	margin: 0px;
`;

const DetailTitle = styled.p`
	font-weight: 500;
	width: fit-content;
	margin: ${(props) => props?.margin || '19px auto'};
`;

const CustomAccordion = styled(Accordion)`
	box-shadow: none !important;
	width: ${(props) => props?.width};
	max-width: ${(props) => props?.maxWidth};
	margin-bottom: 30px;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 0px !important;
	&::before,
	&::after {
		display: none;
	}
`;

const CustomAccordionSummary = styled(AccordionSummary)`
	& .MuiAccordionSummary-content {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0px !important;
	}
`;

const OutlinedInputReadOnly = styled(OutlinedInput)`
	& label {
		color: rgba(0, 0, 0, 0.38) !important;
		-webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
	}
	& a {
		color: #007bff;
		-webkit-text-fill-color: #007bff;
	}
`;

const RemoveBorderContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	gap: 10px;
	& fieldset {
		border: 0px;
	}
`;

const PlaceHolderText = styled.p`
	width: fit-content;
	min-width: fit-content;
	margin-right: 15px;
	margin-bottom: 0px;
	color: rgba(0, 0, 0, 0.38) !important;
	-webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
`;

const CustomInput = styled(Input)`
	width: 100%;
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	box-sizing: border-box;
	background-color: white;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	font-height: 150%;
	letter-spacing: 0.15px;
`;

const CustomLabel = styled(InputLabel)`
	color: ${(props) =>
		props?.disabled
			? props?.value
				? 'black!important;'
				: 'rgba(0, 0, 0, 0.38)!important'
			: 'rgba(0, 0, 0, 0.6)!important'};

	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 400 !important;
	font-height: 150%; /* 24px */
	letter-spacing: 0.15px;
`;

const CustomDatePicker = styled(DatePicker)`
	& .MuiInputLabel-shrink {
		color: ${(props) => (props?.disabled ? 'black!important;' : '')};
		font-weight: ${(props) => (props?.disabled ? '500!important;' : '')};
		-webkit-text-fill-color: ${(props) =>
			props?.disabled ? 'black!important;' : 'rgba(0, 0, 0, 0.6)'};
	}
`;

const CustomDateTimePicker = styled(DateTimePicker)`
	& label {
		color: ${(props) => (props?.disabled ? 'black!important;' : '')};
		font-weight: ${(props) => (props?.disabled ? '500!important;' : '')};
		-webkit-text-fill-color: ${(props) =>
			props?.disabled ? 'black!important;' : 'rgba(0, 0, 0, 0.6)'};
	}
`;

const CustomTimePicker = styled(TimePicker)`
	& label {
		color: ${(props) => (props?.disabled ? 'black!important;' : '')};
		font-weight: ${(props) => (props?.disabled ? '500!important;' : '')};
		-webkit-text-fill-color: ${(props) =>
			props?.disabled ? 'black!important;' : 'rgba(0, 0, 0, 0.6)'};
	}
`;

const EditSelect = styled(FormControl)`
	border: 1px solid rgba(0, 0, 0, 0.23) !important;
	border-radius: 4px;

	& label {
		background-color: white;
		padding: 0px 5px;
	}

	& select {
		padding-left: 14px;
	}

	& .MuiInputBase-root {
		margin-top: 8px;
	}

	& .MuiInputBase-root:hover::before {
		border: 0px !important;
	}

	& .MuiInputBase-root:hover::after {
		border: 0px !important;
	}
	// .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after

	& .MuiInputBase-root::before {
		border: 0px;
	}
`;

const EditTextField = styled(TextField)`
	& .MuiInputBase-root {
		max-height: 49px;
	}
`;

const SaveFormulaBox = styled.div`
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.1);
	display: flex;
	min-height: 1.4375em;
	box-sizing: content-box;
	padding: 16.5px 14px;
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.87);
	font-size: 1rem;
	font-weight: 400;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const BorderOutline = styled.div`
	width: 100%;
	border: ${(props) => (props?.disabled ? ' ' : '1px solid rgba(0, 0, 0, 0.23)')};
	border-radius: 4px;
	padding: 16px 14px;
	background: ${(props) => (props?.disabled ? '#f8f8f8' : '')};
	display: flex;
	gap: 10px;
	& .MuiRating-sizeLarge label {
		margin-bottom: 0px !important;
	}
`;

const CustomTextFieldWrapper = styled.div`
	position: relative;
	width: 100%;
	box-sizing: border-box;
`;

const Label = styled.label`
	position: absolute;
	top: -8px;
	left: 10px;
	background-color: white;
	color: rgba(0, 0, 0, 0.6);
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.4375em;
	letter-spacing: 0.00938em;
	padding: 0 5px;
`;

const StyledInput = styled.input`
	width: 100%;
	padding: 10px;
	border: 1px solid #ced4da;
	border-radius: 4px;
	box-sizing: border-box;
`;

const CustomHelperText = styled.span`
	font-size: 12px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.6);
`;

const AsteriskSpan = styled.span`
	-webkit-text-fill-color: #e65100;
	color: #e65100;
	font-size: 30px;
	vertical-align: -8px;
`;

export default {
	LogicContainer,
	TextAreaContainer,
	InputContainer,
	ButtonsContainer,
	AndOrLabel,
	AllDependancies,
	CurrentDependanciesContainer,
	CurrentDependancies,
	GroupedDependancies,
	dependancy,
	DetailTitle,
	CustomAccordion,
	CustomAccordionSummary,
	OutlinedInputReadOnly,
	RemoveBorderContainer,
	PlaceHolderText,
	CustomInput,
	CustomLabel,
	CustomDatePicker,
	CustomDateTimePicker,
	CustomTimePicker,
	EditSelect,
	EditTextField,
	SaveFormulaBox,
	BorderOutline,
	CustomTextFieldWrapper,
	Label,
	Input: StyledInput,
	CustomHelperText,
	AsteriskSpan
};
