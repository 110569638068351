import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import StyledWrapper from './style';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { getElementIcon } from '../utils';
export const MUIDatePicker = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, onChange, required, isFormBuilder } = props;
	const [value, setValue] = React.useState(defaultValue || null);

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
				{props?.data?.showTimeSelect ? (
					props?.data?.showTimeSelectOnly ? (
						<StyledWrapper.CustomTimePicker
							name={name}
							label={
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
									{props.data?.required && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</>
							}
							value={value}
							disabled={disabled}
							onChange={(newValue) => {
								setValue(newValue);
								onChange && onChange(newValue);
							}}
							inputRef={ref}
							renderInput={(params) => (
								<TextField
									error={props?.data?.error}
									InputLabelProps={{ shrink: isFormBuilder || undefined }}
									required={required}
									name={name}
									data-testid={`datepicker-textfield`}
									{...params}
								/>
							)}
						/>
					) : (
						<StyledWrapper.CustomDateTimePicker
							name={name}
							minDate={props?.data?.min_date || props?.data?.min_value}
							maxDate={props?.data?.max_date || props?.data?.max_value}
							label={
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
									{props.data?.required && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</>
							}
							disabled={disabled}
							value={value}
							onChange={(newValue) => {
								setValue(newValue);
								onChange && onChange(newValue);
							}}
							inputRef={ref}
							renderInput={(params) => (
								<TextField
									error={props?.data?.error}
									InputLabelProps={{ shrink: isFormBuilder || undefined }}
									required={required}
									name={name}
									data-testid={`datepicker-textfield`}
									style={{ width: '100%' }}
									{...params}
								/>
							)}
						/>
					)
				) : (
					<StyledWrapper.CustomDatePicker
						minDate={props?.data?.min_date || props?.data?.min_value}
						maxDate={props?.data?.max_date || props?.data?.max_value}
						label={
							<>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
								{isFormBuilder && (
									<div>
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</div>
								)}
								{props.data?.required && (
									<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
								)}
							</>
						}
						value={value}
						disabled={disabled}
						onChange={(newValue) => {
							setValue(newValue);
							onChange && onChange(newValue);
						}}
						inputRef={ref}
						renderInput={(params) => (
							<TextField
								error={props?.data?.error}
								InputLabelProps={{ shrink: isFormBuilder || undefined }}
								required={required}
								name={name}
								data-testid={`datepicker-textfield`}
								style={{ width: '100%' }}
								{...params}
							/>
						)}
					/>
				)}
			</LocalizationProvider>
		</>
	);
});

MUIDatePicker.displayName = 'MUI_DatePicker';
Registry.register('MUI_DatePicker', MUIDatePicker);
