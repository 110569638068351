import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import StyledWrapper from './style';

import {
	Alert,
	Collapse,
	IconButton,
	Box,
	Stepper,
	Step,
	StepLabel,
	Button,
	Typography,
	CircularProgress,
	Switch,
	FormControlLabel
} from '@mui/material';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

import { ReactFormGenerator } from '../FormBuilderLibrary';
import { checkAllConditions } from '../../utils/conditionalLogic';
import {
	usePostFormValuesMutation,
	useUpdateFormStatusMutation,
	useUpdateMarkCompleteMutation
} from '../../services/endpoints/formBuilderEndpoints';

import { Instructions } from '../FormBuilder/customComponents/Instructions';
import { setFormData } from '../FormBuilderLibrary/slice';
import { SimulateSaveButton } from '../SimulateSaveButton/SimulateSaveButton';
import { useSaveCoreInfoMutation } from '../../services/endpoints/programsEndpoints';
import { disabledStatuses } from '../../constants/statuses';
import {
	areRequiredFieldsCompleted,
	areRequiredFieldsTested,
	exportFormToPDF
} from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import { calculateUserScore } from '../FormBuilder/utils';
import { usePermissions } from '../../hooks/usePermissions';

export default function MultiFormViewer(props) {
	const dispatch = useDispatch();

	const path = window.location.pathname;
	const pathSegments = path.split('/');

	const [alertBalloon, setAlertBalloon] = useState({ isOpen: false });
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const [formSchema, setFormSchema] = useState();
	const [formAnswers, setFormAnswers] = useState([]);
	const [steps, setSteps] = useState(['']);
	const [readOnlyTest, setReadOnlyTest] = useState(false);
	const [readOnlyReset, setReadOnlyReset] = useState(true);
	const [loadingCancel, setLoadingCancel] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [markComplete, setMarkComplete] = useState(props.complete);
	const [canMarkComplete, setCanMarkComplete] = useState(props.complete);
	const [isSaving, setIsSaving] = useState(false);

	const appID = pathSegments.find((segment) => segment.includes('api-'))?.replace('api-', '');
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const reduxFormSchema = useSelector((state) => state?.form?.formSchema);
	const allDependancies = useSelector((state) => state?.form?.dependancies);
	const answers = useSelector((state) => state?.form?.form);
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);
	const readOnly = props.readOnly ?? false;
	const { hasEditPermissions, userAccountTypeID } = usePermissions();

	const [updateFormStatus] = useUpdateFormStatusMutation();
	const [postFormValues] = usePostFormValuesMutation();
	const [saveCoreInfo] = useSaveCoreInfoMutation();
	const [updateMarkComplete] = useUpdateMarkCompleteMutation();

	useEffect(() => {
		if (props.form) {
			setFormSchema(props.form);
			setSteps(props.form?.map(() => ''));
		} else if (reduxFormSchema[0] !== null) {
			let schema = reduxFormSchema?.filter((e) => {
				return e !== undefined;
			});
			setFormSchema(schema);
			setSteps(schema?.map(() => ''));
		}
	}, [props.form, reduxFormSchema]);

	useEffect(() => {
		const canComplete = !!answers?.[1] && areRequiredFieldsCompleted(props.form[0], answers[1]);
		setCanMarkComplete(canComplete);
		if (markComplete && !canComplete) setMarkComplete(false);

		if (props.formAnswers) {
			setFormAnswers(formAnswers);
			checkAllConditions(allDependancies, props.formAnswers);
		} else {
			setFormAnswers(answers);
			checkAllConditions(allDependancies, answers);
			if (answers.length > 0) {
				setReadOnlyReset(false);
			}
		}
		setTimeout(function () {
			setLoadingCancel(false);
		}, 1000);
	}, [activeStep, answers, props.formAnswers]);

	useEffect(() => {
		checkAllConditions(allDependancies, answers);
	});

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleAlert = (message, status) => {
		if (typeof props.setReadOnlyTest === 'function') {
			// preview
			if (status == 'success') {
				props.setReadOnlyTest(true);
			} else {
				props.setReadOnlyTest(false);
			}
		}
		setButtonLoading(false);
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon((prevAlertBalloon) => ({
				...prevAlertBalloon,
				isOpen: false
			}));
		}, 30000);
	};

	useEffect(() => {
		if (!props?.isDataLoading && isSaving) {
			setIsSaving(false);
		}
	}, [props?.isDataLoading]);

	const handleAfterSave = (message, messageType) => {
		setButtonLoading(false);
		setIsSaving(false);
		handleAlert(message, messageType);
	};

	const handleFormSubmit = async (data) => {
		setIsSaving(true);
		setButtonLoading('save');
		if (props.isSimulating) {
			let isValidated = true;
			if (reduxFormSchema.length > 0) {
				isValidated = areRequiredFieldsTested(reduxFormSchema[0], data);
			}

			if (isValidated) {
				handleAlert('Form Saved Successfully!', 'success');
				setReadOnlyTest(true);
				props.setReadOnly(true);
			} else {
				handleAlert('Form Validation Failed!', 'error');
				setReadOnlyTest(false);
				props.setReadOnly(false);
			}

			return;
		}
		const userScore = calculateUserScore(props.form[0], data);

		if (props.disableFooterButtons && !props.hideActionButtons) {
			handleAlert('Form Saved Successfully!', 'success');
			setReadOnlyTest(true);
			setReadOnlyReset(true);
			props.setReadOnly(true);
		} else {
			let noNullAnswers = data.filter((e) => e !== null && e !== undefined);
			let options = {
				formID: props.formID?.split('-')?.[1],
				payload: { noNullAnswers, userScore },
				apiToken: apiToken,
				application_id: appID
			};

			const formSchema = props.apiFormData?.form?.form_schema;
			const coreInfoElements = formSchema?.filter(
				(e) =>
					e?.custom_options?.includes('project-title') || e?.custom_options?.includes('org-name')
			);

			// SAVE CORE INFO
			if (props.apiFormData?.form?.purpose === 'Core Information') {
				const payload = {};
				coreInfoElements?.forEach((e) => {
					const elementAnswer = noNullAnswers?.find((x) => e?.field_name === x?.name);
					if (e?.custom_options?.includes('org-name')) {
						payload['org_name'] = elementAnswer?.value || '';
					}
					if (e?.custom_options?.includes('project-title')) {
						payload['project_title'] = elementAnswer?.value || '';
					}
				});

				await saveCoreInfo({
					...options,
					payload: payload
				})
					.unwrap()
					.then(() => {
						if (props?.newExperience) {
							const completeOptions = {
								id: props.formID?.split('-')?.[1],
								complete: markComplete,
								apiToken: apiToken
							};
							updateMarkComplete(completeOptions)
								.unwrap()
								.then(() => {
									postFormValues(options)
										.unwrap()
										.then(() => {
											if (markComplete) {
												handleAlert('Form Completed Successfully!', 'success');
											} else {
												handleAlert('Form Saved Successfully!', 'success');
											}
											setButtonLoading(false);
											props.setReadOnly && props.setReadOnly(true);
										})
										.catch(() => handleAfterSave('Form Saving Failed!', 'error'));
								})
								.catch(() => handleAfterSave('Form Saving Failed!', 'error'));
						} else {
							postFormValues(options)
								.unwrap()
								.then(() => {
									handleAlert('Form Saved Successfully!', 'success');
									setButtonLoading(false);
									props.setReadOnly && props.setReadOnly(true);
								})
								.catch(() => handleAfterSave('Form Saving Failed!', 'error'));
						}
					})
					.catch(() => handleAfterSave('Form Saving Failed!', 'error'));
			} else {
				if (props?.newExperience) {
					const completeOptions = {
						id: props.formID?.split('-')?.[1],
						complete: markComplete,
						apiToken: apiToken
					};
					updateMarkComplete(completeOptions)
						.unwrap()
						.then(() => {
							postFormValues(options)
								.unwrap()
								.then(() => {
									if (markComplete) {
										handleAlert('Form Completed Successfully!', 'success');
									} else {
										handleAlert('Form Saved Successfully!', 'success');
									}
									setButtonLoading(false);
									props.setReadOnly && props.setReadOnly(true);
								})
								.catch(() => handleAfterSave('Form Saving Failed!', 'error'));
						})
						.catch(() => handleAfterSave('Form Saving Failed!', 'error'));
				} else {
					postFormValues(options)
						.unwrap()
						.then(() => {
							handleAfterSave('Form Saved Successfully!', 'success');
							props.setReadOnly && props.setReadOnly(true);
						})
						.catch(() => handleAfterSave('Form Saving Failed!', 'error'));
				}
			}
		}
	};

	const handleCancel = () => {
		setLoadingCancel(true);
		dispatch(setFormData([1, props.apiFormData?.field_values] || []));
		props.setReadOnly(true);
		setTimeout(function () {
			setLoadingCancel(false);
		}, 1000);
	};

	const handleReopenForm = () => {
		setButtonLoading('reopen');

		let id = props.formID?.split('-')?.[1];
		const options = {
			id: id,
			status: 'Draft (returned)',
			apiToken: apiToken
		};
		const completeOptions = {
			id: id,
			complete: false,
			apiToken: apiToken
		};

		updateMarkComplete(completeOptions)
			.unwrap()
			.then(() => {
				updateFormStatus(options)
					.unwrap()
					.then(() => {
						setMarkComplete(false);
						setButtonLoading(false);
						handleAlert('Status Updated Successfully!', 'success');
					})
					.catch(() => {
						setButtonLoading(false);
						handleAlert('Status Update Failed!', 'error');
					});
			})
			.catch(() => {
				setButtonLoading(false);
				handleAlert('Status Update Failed!', 'error');
			});
	};

	const handleMarkCompleteChange = (e) => {
		setMarkComplete(e.target.checked);
	};

	const handleValidationError = () => {
		if (props?.newExperience) {
			setButtonLoading(false);
			setIsSaving(false);
		}
	};

	const handleCompleteForm = () => {
		const validForm = areRequiredFieldsCompleted(props.form[0], props.formAnswers[1]);
		if (validForm) {
			setButtonLoading('complete');
			let id = props.formID?.split('-')?.[1];
			const options = {
				id,
				status: 'Completed',
				apiToken
			};
			const completeOptions = {
				id: props.formID?.split('-')?.[1],
				complete: true,
				apiToken: apiToken
			};
			updateMarkComplete(completeOptions)
				.unwrap()
				.then(() => {
					updateFormStatus(options)
						.unwrap()
						.then(() => {
							setButtonLoading(false);
							handleAlert('Status Updated Successfully!', 'success');
						})
						.catch(() => {
							setButtonLoading(false);
							handleAlert('Status Update Failed!', 'error');
						});
				})
				.catch(() => {
					setButtonLoading(false);
					handleAlert('Status Update Failed!', 'error');
				});
		} else {
			setButtonLoading(false);
			setIsSaving(false);
			handleAlert('This form contains errors.', 'error');
		}
	};

	const handleSave = async () => {
		setButtonLoading('save');

		if (
			props?.newExperience &&
			markComplete &&
			!areRequiredFieldsCompleted(props.form[0], answers[1])
		) {
			setMarkComplete(false);
		}

		const element = document.getElementById(`${props.formID}-saveButton`);
		element.click();
	};

	useEffect(() => {
		props.setReadOnly(props.readOnly);
	}, [props.readOnly]);

	return (
		<Box sx={{ width: '100%' }}>
			<Helmet>
				<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css" />
				<link
					rel="stylesheet"
					href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
					integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
					crossOrigin="anonymous"
				/>
			</Helmet>
			<Stepper activeStep={activeStep}>
				{steps.map((label) =>
					steps?.length > 1 ? (
						<Step key={label}>
							<StepLabel></StepLabel>
						</Step>
					) : null
				)}
			</Stepper>

			<Collapse in={alertBalloon.isOpen}>
				<Alert
					severity={alertBalloon?.status}
					action={
						<IconButton
							aria-label="close"
							color={alertBalloon?.status}
							size="small"
							onClick={() => setAlertBalloon({ isOpen: false })}
						>
							{alertBalloon?.status != 'success' ? <CloseIcon fontSize="inherit" /> : ''}
						</IconButton>
					}
					sx={{ mb: 2, mt: 2 }}
				>
					{alertBalloon?.message}
				</Alert>
			</Collapse>

			{activeStep === steps.length ? (
				<>
					<Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button onClick={handleReset}>Reset</Button>
					</Box>
				</>
			) : (
				<>
					{props.disableFooterButtons && !props.hideActionButtons && (
						<SimulateSaveButton
							readOnly={readOnly}
							setReadOnly={props.setReadOnly}
							marginBottom={props.marginBottom}
							setOuterLoadingCancel={setLoadingCancel}
							readOnlyTest={readOnlyTest}
							readOnlyReset={readOnlyReset}
							setReadOnlyReset={setReadOnlyReset}
							setReadOnlyTest={setReadOnlyTest}
							setAlertBalloon={setAlertBalloon}
						/>
					)}

					{(formMetaInfo?.instructions || props.formInstructions) && !props.hideInstructions && (
						<Instructions
							data={{ content: props.formInstructions || formMetaInfo?.instructions }}
						/>
					)}

					{props?.newExperience && (
						<StyledWrapper.EditBar>
							<StyledWrapper.LastEdited>
								{'Last edited by: '}
								{props.lastEdited.by === ''
									? '--'
									: `${props.lastEdited.by}, ${props.lastEdited.on}`}
							</StyledWrapper.LastEdited>

							<StyledWrapper.LastEditedActions>
								{!props.isEmbedded &&
									props.formID &&
									props.apiFormData?.status !== 'Not Started' &&
									props.readOnly && (
										<Button
											variant="contained"
											onClick={() =>
												exportFormToPDF(props.apiFormData, props.application_status, appID)
											}
											sx={{ height: '32px', marginTop: '8px' }}
										>
											Export to PDF
										</Button>
									)}
								{hasEditPermissions &&
									props.status?.toLowerCase()?.includes('completed') &&
									!disabledStatuses?.includes(props.application_status) && (
										<LoadingButton
											loading={buttonLoading === 'reopen'}
											variant="contained"
											component="label"
											data-testid={'individualapp-reopen-button'}
											onClick={handleReopenForm}
											sx={{ height: '32px', marginTop: '8px' }}
										>
											Reopen
										</LoadingButton>
									)}
								{!props.disableActionButtons &&
									(hasEditPermissions || !userAccountTypeID) &&
									(props.readOnly ? (
										<Button
											disabled={props.apiFormData?.status === 'Completed'}
											data-testid={'individualapp-edit-button'}
											variant="outlined"
											onClick={() => {
												props.setReadOnly(false);
											}}
											startIcon={<EditIcon />}
											sx={{ height: '32px', marginTop: '8px' }}
										>
											Edit
										</Button>
									) : (
										<>
											<FormControlLabel
												sx={{ marginTop: '6px' }}
												control={
													<Switch checked={markComplete} onChange={handleMarkCompleteChange} />
												}
												label="Mark Complete"
												disabled={buttonLoading || !canMarkComplete}
											/>
											{!props.isEmbedded &&
												props.formID &&
												props.apiFormData?.status !== 'Not Started' && (
													<Button
														variant="contained"
														onClick={() =>
															exportFormToPDF(props.apiFormData, props.application_status, appID)
														}
													>
														Export to PDF
													</Button>
												)}
											<LoadingButton
												loading={buttonLoading === 'save'}
												variant="contained"
												component="label"
												data-testid={'individualapp-save-button'}
												onClick={handleSave}
											>
												Save
											</LoadingButton>
											<Button
												variant="outlined"
												onClick={handleCancel}
												data-testid={'individualapp-cancel-button'}
											>
												Cancel
											</Button>
										</>
									))}
							</StyledWrapper.LastEditedActions>
						</StyledWrapper.EditBar>
					)}

					{!props?.newExperience && (
						<StyledWrapper.LastEditedActions>
							{!props.isEmbedded && props.formID && props.apiFormData?.status !== 'Not Started' && (
								<Button
									variant="contained"
									onClick={() =>
										exportFormToPDF(props.apiFormData, props.application_status, appID)
									}
								>
									Export to PDF
								</Button>
							)}
							{hasEditPermissions &&
								props.status?.toLowerCase()?.includes('completed') &&
								!disabledStatuses?.includes(props.application_status) && (
									<LoadingButton
										loading={buttonLoading === 'reopen'}
										variant="contained"
										component="label"
										data-testid={'individualapp-reopen-button'}
										onClick={handleReopenForm}
									>
										Reopen
									</LoadingButton>
								)}
							{!props.disableActionButtons &&
								(hasEditPermissions || !userAccountTypeID) &&
								(props.readOnly ? (
									<>
										<Button
											disabled={props.apiFormData?.status === 'Completed'}
											data-testid={'individualapp-edit-button'}
											variant="outlined"
											onClick={() => {
												props.setReadOnly(false);
											}}
											startIcon={<EditIcon />}
										>
											{props.formID?.includes('afi') ? 'Edit Application Form' : 'Edit'}
										</Button>

										{props.status?.toLowerCase()?.includes('draft') && (
											<LoadingButton
												loading={buttonLoading === 'complete'}
												variant="contained"
												component="label"
												data-testid={'individualapp-save-button'}
												onClick={handleCompleteForm}
											>
												Complete
											</LoadingButton>
										)}
									</>
								) : (
									<>
										<LoadingButton
											loading={buttonLoading === 'save'}
											variant="contained"
											component="label"
											data-testid={'individualapp-save-button'}
											onClick={handleSave}
										>
											Save
										</LoadingButton>
										<Button
											variant="outlined"
											onClick={handleCancel}
											data-testid={'individualapp-cancel-button'}
										>
											Cancel
										</Button>
									</>
								))}
						</StyledWrapper.LastEditedActions>
					)}

					{loadingCancel || isSaving ? (
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								height: '100%',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<ReactFormGenerator
							download_path=""
							disableFooterButtons={props.disableFooterButtons}
							formID={props.formID || 'testing-form'}
							back_action={''}
							back_name="Back"
							answer_data={
								props.formAnswers ? props.formAnswers[activeStep + 1] : formAnswers[activeStep + 1]
							}
							action_name="Save"
							hide_actions={true}
							form_action="/"
							form_method="POST"
							onSubmit={handleFormSubmit}
							NextPage={() => handleNext()}
							GoBack={() => handleBack()}
							activeStep={activeStep}
							totalSteps={steps}
							variables={[]}
							formIndex={activeStep + 1}
							read_only={props.isSimulating === true ? readOnlyTest : readOnly}
							data={formSchema ? formSchema[activeStep] : []}
							funded={props.funded}
							onValidationError={handleValidationError}
						/>
					)}
				</>
			)}
		</Box>
	);
}
