import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getOldBlueColor } from '../../utils/utilFunctions';

const OutermostContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 30px;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-wrap: no-wrap;
	align-items: flex-start;
	padding: ${(props) => props?.padding};
	margin: ${(props) => props?.margin};
	border-radius: 4px;
`;

const DetailTitleContainer = styled.div`
	position: relative;
	display: flex;
	gap: 10px;
	align-items: center;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	background: #e8ecee;
	margin-bottom: 32px;
	padding: 0px 10px;
`;

const DetailTitle = styled.p`
	display: flex;
	gap: 15px;
	font-weight: 500;
	width: fit-content;
	margin: ${(props) => props?.margin || '19px auto'};
`;

const ActionButtonContainer = styled.div`
	display: flex;
	height: fit-content;
	gap: 10px;

	@media (max-width: 1600px) {
		position: relative;
	}
`;

const CustomIconButton = styled(IconButton)`
	background-color: rgba(255, 255, 255, 0.3) !important;
`;

const CardFormContainer = styled.div`
	width: 100%;
	padding: 10px;

	& .react-form-builder-form .rfb-item label {
		color: ${(props) => props?.readOnly && 'black'};
		font-weight: ${(props) => props?.readOnly && '500'};
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FormHeader = styled.div`
	display: flex;
	width: 100%;
	margin: 30px 0px;
	justify-content: space-between;
	align-items: center;
`;

const LastEdited = styled.h6`
	margin: 0px;
`;

const LastEditedActions = styled.div`
	display: flex;
	gap: 5px;
`;

const BackIconContainer = styled.div`
	width: 100%;
	position: absolute;
	top: -16px;
`;

//TOTAL SCORE SECTION
const ScoreOuterContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const ScoreContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

const Score = styled.p`
	font-size: 24px;
	margin-bottom: 0px;
	color: ${(props) => (props?.score >= 90 ? '#388E3C' : props?.score >= 75 ? '#FF9800' : 'black')};
`;

const ScoreOverall = styled.h6`
	color: rgba(0, 0, 0, 0.6);
	margin-bottom: 0px;
`;

// FORM INFO
const MetaInfoContainer = styled.div`
	width: 100%;
`;

const MetaTitleContainer = styled.div`
	position: relative;
	width: 100%;
	height: 60px;
	min-height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #90a4ae;
	border-radius: 4px 4px 0px 0px;
	font-weight: 500;
	font-size: 16px;
	color: white;
`;

const Accordion = styled(MuiAccordion)`
	background: #cfd8dc !important;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
	font-weight: 700;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);
	max-width: fit-content;
`;

const AccordionDetails = styled(MuiAccordionDetails)``;

const FormInfoOutermost = styled.form`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-left: 15px;

	&::before {
		content: ' ';
		position: absolute;
		left: 0px;
		height: 100%;
		width: 3px;
		background: #90a4ae;
	}
`;

const FormRowTitle = styled.div`
	display: flex;
	align-items: center;
	color: black !important;
	gap: 6px;
	font-weight: 700;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);
	max-width: fit-content;
	margin-bottom: 15px;
`;

const FormInfoRow = styled.div`
	width: 100%;
	display: flex;
	gap: 10px;
	justify-content: ${(props) => props?.justifyContent};

	& .MuiInputBase-root.Mui-disabled {
		border: 1px dashed rgba(0, 0, 0, 0.23);
		-webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
	}

	& .MuiInputBase-root.Mui-disabled div {
		-webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
	}

	& .isChanged .MuiOutlinedInput-notchedOutline {
		border-width: 2px !important;
	}

	& input {
		border-radius: 6px;
		background: white;
	}
	& .Mui-focused {
		& fieldset {
			border-color: ${getOldBlueColor()} !important;
		}
		& textarea {
			border-color: ${getOldBlueColor()} !important;
		}
	}
	& label {
		&.Mui-focused {
			color: rgba(0, 0, 0, 0.6);
		}
	}
`;

// STAGE LOGIC
const StageLogicContainer = styled.div`
	border-radius: 0px 0px 4px 4px;
	border: 1px dashed #90a4ae;
	background: #eceff1;
	padding: 10px;
	width: 100%;

	& .MuiDataGrid-columnSeparator {
		visibility: ${(props) => props?.hideResizeIcon && 'hidden'};
	}

	& .MuiDataGrid-columnSeparator--resizable {
		visibility: visible !important ;
	}

	& .MuiDataGrid-rowReorderCell--draggable {
		position: relative;
		left: -12px;
	}
`;

const TopInfo = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25px 6px;
`;

const InfoLeft = styled.p`
	font-weight: 400;
	font-size: 16px;
	color: #78909c;
	margin: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
`;

const CustomWarningText = styled.h6`
	color: #ed6c02;
	opacity: 0.6;
	margin: 0px !important;

	& ul li {
		color: #ed6c02;
		opacity: 0.6
		margin: 0px !important;
	}
`;

export default {
	OutermostContainer,
	InnerContainer,
	DetailTitleContainer,
	DetailTitle,
	ActionButtonContainer,
	CustomIconButton,
	CardFormContainer,
	FormContainer,
	FormHeader,
	LastEdited,
	LastEditedActions,
	BackIconContainer,
	ScoreOuterContainer,
	ScoreContainer,
	Score,
	ScoreOverall,
	MetaInfoContainer,
	MetaTitleContainer,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormRowTitle,
	FormInfoRow,
	FormInfoOutermost,
	StageLogicContainer,
	TopInfo,
	InfoLeft,
	CustomWarningText
};
